import React, { useState } from "react";

import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import ReactGA from "react-ga";

const dailies: any[] = [];

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

ReactGA.initialize("G-C9ME962K5V");
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  const [temp, setTemp] = useState(98.6);
  const [specialDay, setSpecialDay] = useState(false);

  const handleDailyFormSubmit = (e: any) => {
    e.preventDefault();
    dailies.push({
      temp,
      specialDay,
    });
    console.log("here");
    setTemp(98.6);
    setSpecialDay(false);
  };

  return (
    <Container sx={{ mt: 1 }} maxWidth={"xl"}>
      <h1>bumptracker</h1>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ padding: "10px", height: "300px" }}>
            <p>welcome to bumptracker.</p>
            <ul>
              {dailies.map((d, idx) => (
                <li key={idx}>
                  {d.temp} {d.specialDay ? "Special" : "No"}
                </li>
              ))}
            </ul>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ padding: "10px", height: "300px" }}>
            <p>here you can track your daily info.</p>
            <form onSubmit={handleDailyFormSubmit}>
              <FormGroup sx={{ width: "50%" }}>
                <TextField
                  type="number"
                  label="Temperature"
                  value={temp}
                  onChange={(e) => setTemp(Number(e.target.value))}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={specialDay}
                      onChange={(e) => setSpecialDay(e.target.checked)}
                    />
                  }
                  label="Special Day?"
                />
                <Button variant="contained" type="submit">
                  Save Daily Info
                </Button>
              </FormGroup>
            </form>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ padding: "10px", height: "500px" }}>
            <p>and enter other events.</p>
            <Box sx={{ height: "400px", width: "400px" }}>
              <FullCalendar height={450} plugins={[dayGridPlugin]} />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ padding: "10px", height: "500px" }}>
            <p>to help you track the bump!</p>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                width={500}
                height={300}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="pv"
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                />
                <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
              </LineChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default App;
